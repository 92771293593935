import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import List from "../components/List";

const CategoryTemplate = props => {
  const {
    pageContext: { category },
    data: {
      allMarkdownRemark: { totalCount, edges },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline theme={theme}>
            <span>Artículos en la categoría</span> <FaTag />
            {category}
          </Headline>
          <p className="meta">
            Tenemos <strong>{totalCount}</strong> articulo{totalCount > 1 ? "s" : ""} en esta
            categoría.
          </p>
          <List edges={edges} theme={theme} />
        </header>
      </Article>
      )}
      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default CategoryTemplate;

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategory($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            category
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
